import React from 'react'
import { Typography, DialogContent, DialogActions, Box, Button } from 'shared-components/material/core'
import { CenteredForm } from 'shared-components/layout'

export default function Success(
  { 
    toggleAiAnalysisModal
  } : {
    toggleAiAnalysisModal: (event?: object, reason?: string) => void
  }) {
  return (
    <>
      <DialogContent sx={{ display: 'flex', height: "536px", justifyContent: 'center', alignItems: 'center' }}>
          <CenteredForm>
          <Typography variant="body1" component="p">
              Payment Successful!
          </Typography>
        </CenteredForm>
      </DialogContent>
      <DialogActions>
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            pt: 2,
            width: '100%'
        }}>
          <Button 
            onClick={toggleAiAnalysisModal}
            variant="contained"
          >
              Done
          </Button>
        </Box>
      </DialogActions>
    </>
  )
}
