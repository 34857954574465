import React, { memo } from 'react'
import moment from 'moment'
import { ExSet } from 'generated/graphql'
import validator from 'validator'
import { Link, Typography } from 'shared-components/material/core'
import { useNavigate } from 'react-router-dom'
import { exSetStatuses } from 'shared-components/utils'

function ExSetMeta({ exSet }: { exSet: ExSet }) {
  const navigate = useNavigate()
  const { exSetStatus, owner } = exSet
  console.log(exSet)
  return (
    <>
      <Typography color="inherit" variant="h5" component="h2" gutterBottom>
          <Link 
            component="button" 
            variant="body1" 
            onClick={() => navigate(`/app/cases/${exSet.exSetDisputeId}/exhibit-set/${exSet.exSetId}`)}
          >
            {exSet.exSetName && validator.unescape(exSet.exSetName)}
          </Link>
      </Typography>
      <Typography variant="body1" gutterBottom>
          <strong>Owner:</strong> {owner?.userFirstname} {owner?.userLastname} <br />
          <strong>Created:</strong> {moment(exSet.exSetCreatedAt).format('MMMM Do YYYY')} <br />
          <strong>Status:</strong> {exSetStatuses[exSetStatus as number].status} <br />
      </Typography>
    </>
  )
}

export default memo(ExSetMeta)
